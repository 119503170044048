define("ember-ref-bucket/helpers/ref-to", ["exports", "ember-ref-bucket/utils/ref"], function (_exports, _ref3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class RefToHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_watcher", null);
    }
    compute(_ref, _ref2) {
      let [name] = _ref;
      let {
        bucket,
        tracked
      } = _ref2;
      const bucketRef = bucket || Ember.getOwner(this);
      if (this._name !== name) {
        if (this._watcher) {
          Ember._unregisterDestructor(this, this._watcher);
        }
        this._watcher = (0, _ref3.watchFor)(name, bucketRef, () => {
          this.recompute();
        });
        Ember._registerDestructor(this, this._watcher);
        this._name = name;
      }
      if (tracked) {
        return (0, _ref3.bucketFor)(bucketRef).getTracked(name);
      } else {
        return (0, _ref3.bucketFor)(bucketRef).get(name);
      }
    }
  }
  _exports.default = RefToHelper;
});